import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { PageHeader, Banner } from '../components/utils'
import img from '../assets/images/travel.jpg'

import styled from 'styled-components'

const ElementsPage = () => (
  <Layout>
			<ElementsPageWrapper>
 					<header className="main">
 						<h1>Element Styles</h1>
 					</header>

									{/* <!-- Content --> */}
										<h2 id="content">Sample Content</h2>
										<p>Praesent ac adipiscing ullamcorper semper ut amet ac risus. Lorem sapien ut odio odio nunc. Ac adipiscing nibh porttitor erat risus justo adipiscing adipiscing amet placerat accumsan. Vis. Faucibus odio magna tempus adipiscing a non. In mi primis arcu ut non accumsan vivamus ac blandit adipiscing adipiscing arcu metus praesent turpis eu ac lacinia nunc ac commodo gravida adipiscing eget accumsan ac nunc adipiscing adipiscing lorem ipsum dolor sit amet nullam veroeros adipiscing.</p>
										<div class="row">
											<div class="col-6 col-12-small">
												<h3>Sem turpis amet semper</h3>
												<p>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat commodo eu sed ante lacinia. Sapien a lorem in integer ornare praesent commodo adipiscing arcu in massa commodo lorem accumsan at odio massa ac ac. Semper adipiscing varius montes viverra nibh in adipiscing blandit tempus accumsan.</p>
											</div>
											<div class="col-6 col-12-small">
												<h3>Magna odio tempus commodo</h3>
												<p>In arcu accumsan arcu adipiscing accumsan orci ac. Felis id enim aliquet. Accumsan ac integer lobortis commodo ornare aliquet accumsan erat tempus amet porttitor. Ante commodo blandit adipiscing integer semper orci eget. Faucibus commodo adipiscing mi eu nullam accumsan morbi arcu ornare odio mi adipiscing nascetur lacus ac interdum morbi accumsan vis mi accumsan.</p>
											</div>
											{/* <!-- Break --> */}
											<div class="col-4 col-12-medium">
												<h3>Interdum sapien gravida</h3>
												<p>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat eu sed ante lacinia sapien lorem accumsan varius montes viverra nibh in adipiscing blandit.</p>
											</div>
											<div class="col-4 col-12-medium">
												<h3>Faucibus consequat lorem</h3>
												<p>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat eu sed ante lacinia sapien lorem accumsan varius montes viverra nibh in adipiscing blandit.</p>
											</div>
											<div class="col-4 col-12-medium">
												<h3>Accumsan montes viverra</h3>
												<p>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat eu sed ante lacinia sapien lorem accumsan varius montes viverra nibh in adipiscing blandit.</p>
											</div>
										</div>

									<hr class="major" />

									{/* <!-- Elements --> */}
										<h2 id="elements">Elements</h2>
										<div class="row gtr-200">
											<div class="col-6 col-12-medium">

												{/* <!-- Text stuff --> */}
													<h3>Text</h3>
													<p>This is <b>bold</b> and this is <strong>strong</strong>. This is <i>italic</i> and this is <em>emphasized</em>.
													This is <sup>superscript</sup> text and this is <sub>subscript</sub> text.
													This is <u>underlined</u> and this is code: <code></code>.
													Finally, this is a <a href="#">link</a>.</p>
													<hr />
													<h2>Heading Level 2</h2>
													<h3>Heading Level 3</h3>
													<h4>Heading Level 4</h4>
													<hr />
													<p>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat eu sed ante lacinia sapien lorem accumsan varius montes viverra nibh in adipiscing blandit tempus accumsan.</p>

												{/* <!-- Lists --> */}
													<h3>Lists</h3>
													<div class="row">
														<div class="col-6 col-12-small">

															<h4>Unordered</h4>
															<ul>
																<li>Dolor etiam magna etiam.</li>
																<li>Sagittis lorem eleifend.</li>
																<li>Felis dolore viverra.</li>
															</ul>

															<h4>Alternate</h4>
															<ul class="alt">
																<li>Dolor etiam magna etiam.</li>
																<li>Sagittis lorem eleifend.</li>
																<li>Felis feugiat viverra.</li>
															</ul>

														</div>
														<div class="col-6 col-12-small">

															<h4>Ordered</h4>
															<ol>
																<li>Dolor etiam magna etiam.</li>
																<li>Etiam vel lorem sed viverra.</li>
																<li>Felis dolore viverra.</li>
																<li>Dolor etiam magna etiam.</li>
																<li>Etiam vel lorem sed viverra.</li>
																<li>Felis dolore viverra.</li>
															</ol>

															<h4>Icons</h4>
															<ul class="icons">
																<li><a href="#" class="icon fa-twitter"><span class="label">Twitter</span></a></li>
																<li><a href="#" class="icon fa-facebook"><span class="label">Facebook</span></a></li>
																<li><a href="#" class="icon fa-instagram"><span class="label">Instagram</span></a></li>
																<li><a href="#" class="icon fa-github"><span class="label">Github</span></a></li>
																<li><a href="#" class="icon fa-dribbble"><span class="label">Dribbble</span></a></li>
																<li><a href="#" class="icon fa-tumblr"><span class="label">Tumblr</span></a></li>
															</ul>

														</div>
													</div>
													<h4>Definition</h4>
													<dl>
														<dt>Item1</dt>
														<dd>
															<p>Lorem ipsum dolor vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent. Lorem ipsum dolor.</p>
														</dd>
														<dt>Item2</dt>
														<dd>
															<p>Lorem ipsum dolor vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent. Lorem ipsum dolor.</p>
														</dd>
														<dt>Item3</dt>
														<dd>
															<p>Lorem ipsum dolor vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent. Lorem ipsum dolor.</p>
														</dd>
													</dl>

													<h4>Actions</h4>
													<ul class="actions">
														<li><a href="#" class="button primary">Default</a></li>
														<li><a href="#" class="button">Default</a></li>
													</ul>
													<ul class="actions small">
														<li><a href="#" class="button primary small">Small</a></li>
														<li><a href="#" class="button small">Small</a></li>
													</ul>
													<div class="row">
														<div class="col-6 col-12-small">
															<ul class="actions stacked">
																<li><a href="#" class="button primary">Default</a></li>
																<li><a href="#" class="button">Default</a></li>
															</ul>
														</div>
														<div class="col-6 col-12-small">
															<ul class="actions stacked">
																<li><a href="#" class="button primary small">Small</a></li>
																<li><a href="#" class="button small">Small</a></li>
															</ul>
														</div>
														<div class="col-6 col-12-small">
															<ul class="actions stacked">
																<li><a href="#" class="button primary fit">Default</a></li>
																<li><a href="#" class="button fit">Default</a></li>
															</ul>
														</div>
														<div class="col-6 col-12-small">
															<ul class="actions stacked">
																<li><a href="#" class="button primary small fit">Small</a></li>
																<li><a href="#" class="button small fit">Small</a></li>
															</ul>
														</div>
													</div>

													<h4>Pagination</h4>
													<ul class="pagination">
														<li><span class="button disabled">Prev</span></li>
														<li><a href="#" class="page active">1</a></li>
														<li><a href="#" class="page">2</a></li>
														<li><a href="#" class="page">3</a></li>
														<li><span>&hellip;</span></li>
														<li><a href="#" class="page">8</a></li>
														<li><a href="#" class="page">9</a></li>
														<li><a href="#" class="page">10</a></li>
														<li><a href="#" class="button">Next</a></li>
													</ul>

												{/* <!-- Blockquote --> */}
													<h3>Blockquote</h3>
													<blockquote>Lorem ipsum dolor vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent. Lorem ipsum dolor. Lorem ipsum dolor vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus.</blockquote>

												{/* <!-- Table --> */}
													<h3>Table</h3>

													<h4>Default</h4>
													<div class="table-wrapper">
														<table>
															<thead>
																<tr>
																	<th>Name</th>
																	<th>Description</th>
																	<th>Price</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>Item1</td>
																	<td>Ante turpis integer aliquet porttitor.</td>
																	<td>29.99</td>
																</tr>
																<tr>
																	<td>Item2</td>
																	<td>Vis ac commodo adipiscing arcu aliquet.</td>
																	<td>19.99</td>
																</tr>
																<tr>
																	<td>Item3</td>
																	<td> Morbi faucibus arcu accumsan lorem.</td>
																	<td>29.99</td>
																</tr>
																<tr>
																	<td>Item4</td>
																	<td>Vitae integer tempus condimentum.</td>
																	<td>19.99</td>
																</tr>
																<tr>
																	<td>Item5</td>
																	<td>Ante turpis integer aliquet porttitor.</td>
																	<td>29.99</td>
																</tr>
															</tbody>
															<tfoot>
																<tr>
																	<td colspan="2"></td>
																	<td>100.00</td>
																</tr>
															</tfoot>
														</table>
													</div>

													<h4>Alternate</h4>
													<div class="table-wrapper">
														<table class="alt">
															<thead>
																<tr>
																	<th>Name</th>
																	<th>Description</th>
																	<th>Price</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>Item1</td>
																	<td>Ante turpis integer aliquet porttitor.</td>
																	<td>29.99</td>
																</tr>
																<tr>
																	<td>Item2</td>
																	<td>Vis ac commodo adipiscing arcu aliquet.</td>
																	<td>19.99</td>
																</tr>
																<tr>
																	<td>Item3</td>
																	<td> Morbi faucibus arcu accumsan lorem.</td>
																	<td>29.99</td>
																</tr>
																<tr>
																	<td>Item4</td>
																	<td>Vitae integer tempus condimentum.</td>
																	<td>19.99</td>
																</tr>
																<tr>
																	<td>Item5</td>
																	<td>Ante turpis integer aliquet porttitor.</td>
																	<td>29.99</td>
																</tr>
															</tbody>
															<tfoot>
																<tr>
																	<td colspan="2"></td>
																	<td>100.00</td>
																</tr>
															</tfoot>
														</table>
													</div>

											</div>
											<div class="col-6 col-12-medium">

												{/* <!-- Buttons --> */}
													<h3>Buttons</h3>
													<ul class="actions">
														<li><a href="#" class="button primary">Primary</a></li>
														<li><a href="#" class="button">Default</a></li>
													</ul>
													<ul class="actions">
														<li><a href="#" class="button large">Large</a></li>
														<li><a href="#" class="button">Default</a></li>
														<li><a href="#" class="button small">Small</a></li>
													</ul>
													<ul class="actions">
														<li><a href="#" class="button primary large">Large</a></li>
														<li><a href="#" class="button primary">Default</a></li>
														<li><a href="#" class="button primary small">Small</a></li>
													</ul>
													<ul class="actions fit">
														<li><a href="#" class="button primary fit">Fit</a></li>
														<li><a href="#" class="button fit">Fit</a></li>
													</ul>
													<ul class="actions fit small">
														<li><a href="#" class="button primary fit small">Fit + Small</a></li>
														<li><a href="#" class="button fit small">Fit + Small</a></li>
													</ul>
													<ul class="actions">
														<li><a href="#" class="button primary icon fa-search">Icon</a></li>
														<li><a href="#" class="button icon fa-download">Icon</a></li>
													</ul>
													<ul class="actions">
														<li><span class="button primary disabled">Primary</span></li>
														<li><span class="button disabled">Default</span></li>
													</ul>

												{/* <!-- Form --> */}
													<h3>Form</h3>

													
												{/* <!-- Image --> */}
													<h3>Image</h3>

													<h4>Fit</h4>
													<span class="image fit"><img src="images/pic11.jpg" alt="" /></span>
													<div class="box alt">
														<div class="row gtr-50 gtr-uniform">
															<div class="col-4"><span class="image fit"><img src="images/pic01.jpg" alt="" /></span></div>
															<div class="col-4"><span class="image fit"><img src="images/pic02.jpg" alt="" /></span></div>
															<div class="col-4"><span class="image fit"><img src="images/pic03.jpg" alt="" /></span></div>
															{/* <!-- Break --> */}
															<div class="col-4"><span class="image fit"><img src="images/pic03.jpg" alt="" /></span></div>
															<div class="col-4"><span class="image fit"><img src="images/pic01.jpg" alt="" /></span></div>
															<div class="col-4"><span class="image fit"><img src="images/pic02.jpg" alt="" /></span></div>
															{/* <!-- Break --> */}
															<div class="col-4"><span class="image fit"><img src="images/pic02.jpg" alt="" /></span></div>
															<div class="col-4"><span class="image fit"><img src="images/pic03.jpg" alt="" /></span></div>
															<div class="col-4"><span class="image fit"><img src="images/pic01.jpg" alt="" /></span></div>
														</div>
													</div>

													<h4>Left &amp; Right</h4>
													<p><span class="image left"><img src="images/pic01.jpg" alt="" /></span>Lorem ipsum dolor sit accumsan interdum nisi, quis tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent.</p>
													<p><span class="image right"><img src="images/pic02.jpg" alt="" /></span>Lorem ipsum dolor sit accumsan interdum nisi, quis tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent.</p>

												{/* <!-- Box --> */}
													<h3>Box</h3>
													<div class="box">
														<p>Felis sagittis eget tempus primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget. tempus euismod. Magna sed etiam ante ipsum primis in faucibus vestibulum.</p>
													</div>

												{/* <!-- Preformatted Code --> */}
													<h3>Preformatted</h3>
													<pre><code>
                            </code></pre>
											</div>
										</div>
     		</ElementsPageWrapper>
		</Layout>
)

const ElementsPageWrapper = styled.section`
`

export default ElementsPage
